import '../assets/css/style.css';
import '../assets/css/content3.css';
import React from 'react';

export default function Content3(props) {

    return (
        <>
            <section id="arlista">
                <div className="pricelist">
                    <div className="content">
                        <h1>Árlista</h1>
                        <table className="pricelist_table">
                            <tbody>
                                <tr>
                                    <th colSpan={5} >Erősített / Modern géllak</th>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Alap nude / milky white</td>
                                    <td colSpan={4} className="text-end price">5000 Ft-tól</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Színes</td>
                                    <td colSpan={4} className="text-end price">5500 Ft-tól</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Baby boomer, francia, ombre</td>
                                    <td colSpan={4} className="text-end price">5800 Ft-tól</td>
                                </tr>
                                <tr>
                                    <th colSpan={5} >Műköröm építés</th>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Egyszínű mandula / kocka</td>
                                    <td className='text-end price pricelist_lenght'>7000 Ft-tól</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Baby boomer, festett francia</td>
                                    <td colSpan={4} className="text-end price">8000 Ft-tól</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Mandula / kocka beépített francia</td>
                                    <td colSpan={4} className="text-end price">10 000 Ft-tól</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Műköröm töltés</th>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Alap nude / milky white</td>
                                    <td colSpan={4} className="text-end price">6000 Ft-tól</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Színes</td>
                                    <td colSpan={4} className="text-end price">6500 Ft-tól</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Baby boomer, francia, ombre</td>
                                    <td colSpan={4} className="text-end price">7000 Ft-tól</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Extrém forma</th>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Orosz mandula</td>
                                    <td colSpan={4} className="text-end price">15 000 Ft-tól</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Kiegészítők</th>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Ombre</td>
                                    <td colSpan={4} className="text-end price">100 Ft /köröm</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Porok</td>
                                    <td colSpan={4} className="text-end price">100 Ft /köröm</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Manikűr</th>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Műköröm eltávolítása + manikűr</td>
                                    <td colSpan={4} className="text-end price">3900 Ft</td>
                                </tr>
                                <tr className="pricelist_hoverable">
                                    <td className="pricelist_item_name">Japán manikűr</td>
                                    <td colSpan={4} className="text-end price">4300 Ft</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="pricelist_info">
                            <p class="text-center">Az árak az ÁFÁ-t tartalmazzák és forintban értendők.</p>
                            <p class="text-center">Az árváltozás jogát fenntartom!</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
