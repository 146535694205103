import '../assets/css/footer.css';
import footerLogo from '../assets/img/SzummerAdrienn_footerlogo.png';
import { HiPhone } from 'react-icons/hi';
import { AiOutlineMail } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { BsFillGeoAltFill } from "react-icons/bs";
import { IoMdContact } from "react-icons/io";
import { FaClock } from "react-icons/fa";
import footerLTulip from '../assets/img/footer_tulip.png';
import { NavLink } from "react-router-dom";
import { useContext } from 'react';
import { mainContext } from '../context/maincontext.js';
import { scrollOffset } from '../services/navigation';

export default function Footer(props) {
    const { navHeight, setNavActive } = useContext(mainContext);
    return (
        <>
            <section id="kapcsolat">
                <div class="row">
                    <div class="col left_tulip">
                        <img src={footerLTulip} alt="szalonlogo"></img>
                    </div>
                    <div class="col right_tulip">
                        <img src={footerLTulip} alt="szalonlogo"></img>
                    </div>
                </div>
                <div class="footer">
                    <div class="row">
                        <div class="col-lg-3 footer_logo">
                            <img src={footerLogo} alt="szalonlogo"></img>

                        </div>
                        <div class="col-lg-3">
                            <table>
                                <tr>
                                    <td>
                                        <h4><IoMdContact /> Elérhetőségeim</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul>
                                            <p><HiPhone />: +36 30/9114053</p>
                                            <p> <AiOutlineMail />: adryy9424@gmail.com </p>
                                            <a class="a" href="https://www.facebook.com/adrienn.szummer"><AiFillFacebook />: Szummer Adrienn</a>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-3">
                            <table>
                                <tr>
                                    <td>
                                        <h4> <FaClock /> Nyitvatartás</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Előre egyeztetett időpontban!</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-3">
                            <table>
                                <tr>
                                    <td>
                                        <h4><BsFillGeoAltFill /> Hol találsz meg?</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>8065, Nagyveleg, Perczel Mór u. 12.</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mapouter">
                            <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3205.4672057435737!2d18.11105754627835!3d47.366987671741875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476a25f608bd8bc9%3A0x8c70693c0e704307!2sNagyveleg%2C%20Perczel%20M%C3%B3r%20u.%2012%2C%208065!5e1!3m2!1shu!2shu!4v1710358453313!5m2!1shu!2shu" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col copyright">
                            <p>© Minden jog fenntartva | <NavLink
                                to="/impresszum"
                                className="text-base link_underline"
                                onClick={() => [scrollOffset(null, navHeight, "impresszum"), setNavActive("impresszum")]}                                
                                //onClick={() => window.scrollTo({ top: -navHeight, behavior: 'smooth' })}
                            >
                                Impresszum
                            </NavLink></p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}