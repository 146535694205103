// REACT
import { useState, useEffect } from "react";

function useRead(url) {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [isOk, setIsOk] = useState(null);  
    const [error, setError] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        setIsPending(true);                
        try {
          const response = await fetch(url, {
            credentials: "omit",
            // credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
          });        
          if (!response.ok) throw new Error(response.statusText);
          const json = await response.json();
          setIsPending(false);
          setData(json);
          setIsOk(response.ok);        
          setError(null);               
        } catch (error) {      
          setError(`${error} Could not Fetch Data `);       
          setIsPending(false);        
          setIsOk(false);
        }
      };
      fetchData();
    }, [url]);
    return { data, isPending, isOk, error };
  };

  export { useRead };