import '../assets/css/style.css';
import '../assets/css/content1.css';
import React from 'react';
import flower_top from '../assets/img/flower_top.png';
import portrait from '../assets/img/sza_portre.jpg';

export default function Content1(props) {
    return (
        <>
            <section id="rolam">
                <div className="about">
                    <div className="content about_content_1">
                        <h1>Rólam</h1>
                        <div>
                            <div>
                                <h4>
                                Sziasztok! 🌺
                                </h4>                                
                                <img src={portrait} alt="Portré" className="about_content_1_img"></img>
                                <p>
                                    Szummer Adrienn vagyok. A műkörmös szakma iránti szerelem kb 2015-ben kezdődött. Éveken keresztül hobbi szinten készítettem Édesanyámnak és magamnak, majd 2021-ben úgy döntöttem, hogy belevágok és hivatalosan is Kézápoló és Műkörömépítő leszek.
                                    Egy régóta dédelgetett álom vált valóra, amikor a Perfect Nailsnél sikeres vizsgát végeztem el. Ezt követően eljártam továbbképzésekre. 2022 nyarától volt kb másfél év kihagyásom a nehéz terhességem és a kisbabám megszületése miatt.
                                    Ahogy engedték a körülmények újra visszaültem az asztal mögé, folytatva a képzéseket is.
                                    <br></br>
                                    <br></br>
                                    Csodálatos, hogy folyamatosan fejlődik a szakma, mindig van újdonság.
                                    Igyekszem minden nap jobb lenni, mint előző nap és szebbnél szebb körmöket készíteni.
                                    Leginkább a letisztult formák és az egyszerűbb díszítések állnak közel hozzám.
                                    Legnagyobb öröm számomra, ha vendégem elégedett a munkámmal és boldogan viseli az általam készített körmöket.
                                    <br></br>
                                    <br></br>
                                    Szeretettel várok minden leendő és meglévő vendéget!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="about_content_2">
                        <img src={flower_top} alt="Tulipán" className="about_content_2_img"></img>
                    </div>
                </div>
            </section>
        </>
    );
}
