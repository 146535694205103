import '../assets/css/style.css';
import '../assets/css/content4.css';
import loading from "../assets/img/loading.gif"
import React, { useContext, useEffect, useState } from 'react';
import { scrollOffset } from '../services/navigation';
import { mainContext } from '../context/maincontext';
import { useRead } from "../services/useFetch.js";

export default function Content4(props) {
  const { navHeight } = useContext(mainContext);

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(null);
  const [screenwidth, setScreenWidth] = useState(null);


  const GOOGLE_DRIVE_URL_START =
    "https://www.googleapis.com/drive/v2/files?q=%27";
  const GOOGLE_DRIVE_DIR_ID = process.env.REACT_APP_GD_DIR_ID;
  const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
  const GOOGLE_API_KEY = process.env.REACT_APP_GD_API_KEY;
  const GOOGLE_DRIVE_IMG_URL = "https://drive.google.com/thumbnail?id=";


  const fdata = useRead(GOOGLE_DRIVE_URL_START +
    GOOGLE_DRIVE_DIR_ID +
    GOOGLE_DRIVE_URL_END +
    GOOGLE_API_KEY);

  useEffect(() => {
    const width = window.innerWidth;
    setScreenWidth(width);

    console.log(width)
    // Check if the width is smaller than 1200px and execute a function accordingly
    if (width < 1200) {
      setVisibleCount(4);
    } else {
      setVisibleCount(3);
    }
  }, [screenwidth]);

  useEffect(() => {
    if (fdata.isOk) setImages(fdata.data.items);
    setIsLoading(fdata.isPending);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleCount, fdata.isOk, fdata.isPending]);

  function checkFormat(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  function ModalView(props) {
    return (
      <div>
        <div id="modal-container" className="mywork_modal">
          <span className="close">&times;&nbsp;&nbsp;</span>
          <img className="mywork_modal_content" id="curr-modal" alt="" />
        </div>
      </div>
    );
  }

  const showModal = (imgId) => {
    const modal = document.getElementById("modal-container");
    const modalImg = document.getElementById("curr-modal");
    modalImg.src = imgId;
    modal.style.display = "flex";
    const span = document.getElementsByClassName("close")[0];
    span.onclick = function () {
      modal.style.display = "none";
    };
  };

  window.onclick = function hideModal(event) {
    const modaltoclose = document.getElementById("modal-container");
    if (event.target === modaltoclose) {
      modaltoclose.style.display = "none";
    }
  }



  const renderImages = (item, i) => {
    return (
      <>
        <img
          className={"mywork_tile_img col-lg-4 col-md-6 col-sm-12"}
          onClick={() => {
            showModal(GOOGLE_DRIVE_IMG_URL + item.id + "&sz=w1000");
          }}
          src={GOOGLE_DRIVE_IMG_URL + item.id + "&sz=w1000"}
          key={i}
          alt={item.title}
        />
      </>
    )

  }

  return (
    <>
      <section id="munkaim">
        <div className="munkaim">
          <div className="content">
            <h1>Munkáim</h1>
            {<ModalView />}
            <div className="row mywork_content_1">
              {images &&
                images?.map ? images.map((item, i) => {
                  if (checkFormat(item.title)) {
                    if ((visibleCount === -1) || (visibleCount > i)) {
                      return (renderImages(item, i))
                    }
                    else {
                      return ([])
                    }
                  }
                  else {
                    return ([])
                  }
                }) : null}

              {/*  */}

            </div>
            {isLoading &&
              <div className="mywork_loading">
                <img src={loading} alt="Képek betöltése..." />
              </div>
            }
            {images.length > 0 && <button className="mywork_btn" onClick={(e) => [setVisibleCount((visibleCount < images.length) ? visibleCount * 2 : 3), (visibleCount >= images.length) ? scrollOffset(null, navHeight, 'munkaim') : null]}>{(visibleCount >= images.length) ? 'X' : '...'}</button>}
          </div>
        </div>


      </section >
    </>
  );
}
