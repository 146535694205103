import { createBrowserRouter } from 'react-router-dom'
import Layout from '../components/Layout.js'
import Home from '../components/Home.js'
import Impressum from '../components/Impressum.js'

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: [<Home />]
            },            
            {
                path: "/impresszum",
                element: [<Impressum />]
            }
        ]
    }
])