import { RouterProvider } from "react-router-dom";
import { router } from "./services/path.js";
import { mainContext } from "./context/maincontext.js";
import { useState } from 'react';

export default function App() {
  const [navHeight, setNavHeight] = useState(0); //Store navbar height for offset
  const [navActive, setNavActive] = useState(""); //Store active navlink ID

  return (
    <div>
      <mainContext.Provider
        value={{
          navHeight, setNavHeight,
          navActive, setNavActive
        }}
      >
        <RouterProvider
          router={router}
        />
      </mainContext.Provider>
    </div>
  );
}


