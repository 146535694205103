import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer";

export default function Layout(props) {
    return (
        <>
            <Menu />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
}