import Content1 from "./Content1";
// import Content2 from "./Content2"; REMOVED TEMPORARY CAUSE LACK OF NEED
import Content3 from "./Content3";
import Content4 from "./Content4";
import { mainContext } from "../context/maincontext.js"
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollOffset, highlightActiveNavLink } from "../services/navigation.js";
import { useIsMounted } from "../services/useIsMounted.js";

export default function Home(props) {
    const { navHeight, navActive, setNavActive } = useContext(mainContext);    
    const location = useLocation();    
    const isMounted = useIsMounted();

    useEffect(() => {  
        // console.clear();
        // console.log("Aktív: ", navActive);
        if (navActive === "") {
            let active = location.hash; //get section from link, for example: #arlista
            if (active === "" ? active = "rolam" : active = active.slice(1)); //set default or remove '#' from active                                         
            setNavActive(active);
        }         
        highlightActiveNavLink(navActive);                          
        scrollOffset(null, navHeight, navActive);                    
                
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);

    return (
        <>
            <Content1 />
            {/* REMOVED TEMPORARY CAUSE LACK OF NEED */}
            {/* <Content2 /> */}
            <Content4 />
            <Content3 />
        </>
    );

}