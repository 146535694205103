import "../assets/css/style.css";
import { useEffect, useContext } from 'react';
import { scrollOffset, highlightActiveNavLink } from '../services/navigation';
import { mainContext } from '../context/maincontext.js';

export default function Impressum(props) {
    const { navHeight, navActive } = useContext(mainContext);

    useEffect(() => {
        highlightActiveNavLink(navActive);
        scrollOffset(null, navHeight, navActive);
        // window.scrollTo({ top: -navHeight, behavior: 'smooth' });        

        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);
    return (
        <>
            <section id="impresszum">
                <div className="container-fluid impressum">
                    <div className="row">
                        <div className="col">
                            <h1>Impresszum</h1>
                        </div>
                    </div>

                    <div className="impressum_content">
                        <i><h5><b>Vállalkozás adatai</b></h5></i>
                        <br></br>
                        <p><b>Név:</b> Szummer Adrienn </p>
                        <p><b>Adószám:</b> 8464990480</p>
                        <p><b>Cím:</b> 8065, Nagyveleg, Perczel Mór u. 12.</p>
                        <p><b>Telefonszám:</b> +36 30/9114053</p>
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <i><h5><b>Webtárhely szolgáltató adatai</b></h5></i>
                        <br></br>
                        <p><b>Cégnév:</b> Netlify, Inc.</p>
                        <p><b>Cím:</b> 44 Montgomery St Suite 300 San Francisco, California 94104 US</p>
                        <p><b>Webcím:</b> <a href="https://www.netlify.com/">https://www.netlify.com/</a> </p>
                    </div>

                </div>
            </section>
        </>
    );
}