import '../assets/css/style.css';
import '../assets/css/menu.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import ScrollSpy from 'react-scrollspy';
import navlogo from '../assets/img/SzummerAdrienn_navbarlogo2.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { navLinkClick, scrollOffset } from '../services/navigation';
import { mainContext } from '../context/maincontext';

export default function Menu() {
  const { navHeight, setNavHeight, setNavActive } = useContext(mainContext);
  const [stickyClass, setStickyClass] = useState('');
  const refNav = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    setNavHeight(refNav.current.clientHeight);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--Stick navbar to top on scroll down
  const stickNavbar = () => {
    try {
      if (window !== undefined) {
        let windowHeight = window.scrollY;
        windowHeight > 0 ? setStickyClass('navbar_sticky') : setStickyClass('');
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  function floatRightNav() {
    const toggleNav = document.getElementById('floatrightNav');
    toggleNav.classList.add("floatrightNavbar");
  }

  return (
    <Navbar expand="lg" className={`navbar1 ${stickyClass}`} id='navBar' ref={refNav}>
      <Container className={`nav_content`}>
        <NavLink
          to="/"
          className='nav_link navlogo_little'          
          onClick={() => [navLinkClick(), setNavActive('rolam')]}>
          <img
            src={navlogo} alt="Kezdő oldal"></img>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id='toggleNavBtn' onClick={floatRightNav} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" id="floatrightNav">
            <NavLink
              smooth to="/"
              className='nav_link navlogo'              
              onClick={() => [navLinkClick(), setNavActive('rolam')]}>
              <img
                src={navlogo} alt="Kezdő oldal"></img>
            </NavLink>
            <ScrollSpy
              items={['rolam', 'munkaim', 'arlista', 'kapcsolat']}
              currentClassName="active_link"
              offset={-navHeight}>
              <HashLink
                id='rolamLink'
                smooth to='/#rolam'
                className='nav_link'
                scroll={(e) => scrollOffset(e, navHeight, "rolam")}                
                onClick={() => [navLinkClick(), setNavActive('rolam')]}>Rólam</HashLink>

              {/* REMOVED TEMPORARY CAUSE LACK OF NEED */}
              {/* <HashLink
                id='szolgaltatasokLink'
                smooth to='/#szolgaltatasok'
                className='nav_link'
                scroll={(e) => scrollOffset(e, navHeight)}
                onClick={() => [navLinkClick()]}>Szolgáltatások</HashLink> */}

              <HashLink
                id='munkaimLink'
                smooth to='/#munkaim'
                className='nav_link'
                scroll={(e) => scrollOffset(e, navHeight, "munkaim")}
                onClick={() => [navLinkClick(), setNavActive('munkaim')]}>Munkáim</HashLink>
              <HashLink
                id='arlistaLink'
                smooth to='/#arlista'
                className='nav_link'
                scroll={(e) => scrollOffset(e, navHeight, "arlista")}
                onClick={() => [navLinkClick(), setNavActive('arlista')]}>Árlista</HashLink>
              
              <HashLink
                id='kapcsolatLink'
                smooth to='/#kapcsolat'
                className='nav_link'
                scroll={(e) => scrollOffset(e, navHeight, "kapcsolat")}
                onClick={() => [navLinkClick(), setNavActive('kapcsolat')]}>Kapcsolat</HashLink>
            </ScrollSpy>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


